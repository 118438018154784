.blogMain {
    margin: 100px 0px 100px 0px;
    background-color: #dedbfc;
    overflow-x: hidden;
}

.heading1blog {
    display: flex;
    justify-content: center;
    margin-top: 42px;
    font-weight: 400;
    font-size: 22px;
    color: #010101;
}

.heading2 {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 36px;
    margin-top: 42px;
    margin-bottom: 30px;
    color: #141c28;
}

.blogSection {
    margin: 0px auto 45px auto;
    display: flex;
    justify-content: center;
    column-gap: 23px;
}

.blog1 {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 14px 14px 24px 14px;
}

.blog1Img {
    border-radius: 13px;
    object-fit: cover;
}

.blog1Para1 {
    margin: 0px 0px 5px 0px;
    font-weight: 600;
    font-size: 20px;
    color: #141c28;
}

.blog1Para2 {
    margin: 0px 0px 25px 0px;
    font-weight: 400;
    font-size: 18px;
    color: #5a5e65;
}

.blog1Bottom1 {
    column-gap: 16px;
}
.blog1Bottom2 {
    display: flex;
    flex-direction: column;
}
.blog1Para3 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}
.blog1Para4 {
    font-weight: 400;
    font-size: 16px;
    color: #5a5e65;
}

.blogSectionBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading3 {
    font-weight: 400;
    font-size: 16px;
    color: #010101;
}
.hLine {
    border-top: 1px solid #010101;
    width: 74px;
    padding-bottom: 41px;
}

@media only screen and (max-width: 1200px) {
    .heading1blog {
        font-size: 20px;
    }
    .heading2 {
        font-size: 34px;
    }
    .blogSection {
        column-gap: 5px;
    }

    .blog1Para1 {
        font-size: 13px;
    }
    .blog1Para2 {
        font-size: 11px;
    }

    .blog1Para3 {
        font-size: 13px;
    }
    .blog1Para4 {
        font-size: 11px;
    }
    .blog1BottomImg {
        max-height: 40px;
    }
    .heading3 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .blogSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 25px;
    }
    .heading2 {
        font-size: 30px;
    }
    .blog1Para1 {
        font-size: 18px;
    }
    .blog1Para2 {
        font-size: 16px;
    }
    .blog1Para3 {
        font-size: 16px;
    }
    .blog1Para4 {
        font-size: 14px;
    }
    .blog1BottomImg {
        max-height: 50px;
    }

    .heading3 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .blog1Para1 {
        font-size: 13px;
    }
    .blog1Para2 {
        font-size: 11px;
    }
    .heading2 {
        font-size: 26px;
    }
    .blog1Para3 {
        font-size: 13px;
    }
    .blog1Para4 {
        font-size: 11px;
    }
    .blog1BottomImg {
        max-height: 50px;
    }

    .heading3 {
        font-size: 14px;
    }
}
