.enrollmentMain {
    margin: 120px 110px 120px 110px;
}
.enrollmentInner {
    display: flex;
    align-items: center;
    column-gap: 65px;
}
.enrollmentHeading {
    color: #030945;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 30px;
}
.textMainLine {
    font-weight: 600;
    font-size: 18px;
    color: #0c93bf;
}
.enrollmentList {
    font-weight: 400;
    color: #000000;
    font-size: 20px;
    line-height: 38px;
}
.enrollmentImg {
    border-radius: 50px;
}

@media only screen and (max-width: 1536px) {
    .enrollmentMain {
        margin: 120px 70px 120px 70px;
    }
    .enrollmentInner {
        column-gap: 80px;
        margin-right: 0px;
    }
    .enrollmentHeading {
        font-size: 34px;
    }
    .enrollmentList {
        font-size: 19px;
    }
    .enrollmentImg {
        width: 450px;
        height: 420px;
    }
}

@media only screen and (max-width: 1200px) {
    .enrollmentHeading {
        font-size: 28px;
        margin-bottom: 20px;
    }
    .textMainLine {
        font-size: 14px;
    }
    .enrollmentList {
        font-size: 16px;
        line-height: 30px;
    }
    .enrollmentImg {
        width: 400px;
        height: 350px;
    }
    .enrollmentMain {
        margin: 90px 40px 70px 40px;
    }
    .enrollmentInner {
        column-gap: 40px;
    }
}

@media only screen and (max-width: 850px) {
    .enrollmentInner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .enrollmentDesc {
        margin-top: 50px;
    }
    .enrollmentHeading {
        font-size: 26px;
    }
    .enrollmentImg {
        width: 445px;
        height: 400px;
    }
}

@media only screen and (max-width: 450px) {
    .enrollmentHeading {
        font-size: 28px;
    }
    .textMainLine {
        font-size: 12px;
    }
    .enrollmentList {
        font-size: 14px;
    }
    .enrollmentImg {
        width: 300px;
        height: 270px;
    }
    .enrollmentDesc {
        margin-top: 15px;
    }
}
