.navBarOuter {
    padding: 24px 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.logoHeader {
    width: 250px;
    height: 60px;
    cursor: pointer;
}

.navBarOuterLink {
    display: flex;
    align-items: center;
    column-gap: 40px;
}

.navBarLink {
    display: flex;
    color: #010101;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.logoMainHeader {
    width: 160px;
    height: 45px;
    cursor: pointer;
}

.menuHeaderIcon {
    font-size: 24px;
}

@media only screen and (max-width: 1536px) {
    .navBarOuter {
        padding: 24px 100px;
    }
}

@media only screen and (max-width: 1200px) {
    .navBarOuter {
        padding: 24px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .navBarLink {
        font-size: 16px;
    }
    .logoHeader {
        width: 150px;
        height: 40px;
    }
    .navBarOuterLink {
        column-gap: 35px;
    }
}
@media only screen and (max-width: 768px) {
    .navBarLink {
        font-size: 14px;
    }
    .navBarOuter {
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
    }
    .navBarOuterLink {
        column-gap: 30px;
    }
    .logoHeader {
        width: 120px;
        height: 40px;
    }
    .logoMainHeader {
        width: 140px;
        height: 30px;
    }
}
@media only screen and (max-width: 550px) {
    .navBarLink {
        font-size: 14px;
    }
    .navBarOuter {
        padding: 20px 50px;
        display: flex;
        justify-content: space-between;
    }
    .navBarOuterLink {
        column-gap: 30px;
    }
    .logoHeader {
        width: 120px;
        height: 40px;
    }
    .logoMainHeader {
        width: 140px;
        height: 30px;
    }
}
