.footerMain {
    /* background-image: url('../../../assets/images/Background.png'); */
    overflow-x: hidden;
    background-color: #060028;
    position: relative;
}

.footerSvgOne {
    position: absolute;
    right: 0;
}

.footerSvgTwo {
    position: absolute;
    bottom: 0;
}

.footerInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 106px 0px 0px 0px;
    margin: 0px 30px 30px 30px;
    height: -webkit-fill-available;
}

.upSkill {
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    opacity: 60%;
    margin: 0px;
    letter-spacing: 2.8px;
    text-align: center;
}
.request {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    margin: 26px 0px;
    font-size: 50px;
    color: #ffffff;
}
.para1 {
    opacity: 80%;
    font-weight: 400;
    font-size: 18px;
    margin: 0px 0px 56px 0px;
    color: #ffffff;
}
.para2 {
    margin: 54px 0px 54px 0px;
    font-size: 14px;
    opacity: 80%;
    font-weight: 500;
}
.para2 {
    color: #ffff !important;
}
.h_line {
    width: 83%;
    height: 1px;
    opacity: 12%;
    color: #ffffff;
}
.footerOuter {
    margin-top: 42px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
}

.footerOuterLink {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 55px;
}

.footerLink {
    display: flex;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}
.footerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    border: 2.25px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    cursor: pointer;
}
.iconLine {
    display: flex;
    column-gap: 25px;
}
@media only screen and (min-width: 1920px) {
    .iconLine {
        margin-left: -350px;
    }
}
@media only screen and (max-width: 1200px) {
    .footerOuter {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .request {
        font-size: 38px;
    }
    .upSkill {
        font-size: 16px;
    }
}
@media only screen and (max-width: 500px) {
    .footerOuterLink {
        flex-direction: column;
        row-gap: 10px;
    }
    .request {
        font-size: 32px;
    }
    .upSkill {
        font-size: 13px;
    }
}
@media only screen and (max-width: 400px) {
    .footerLink {
        font-size: 12px;
        text-align: center;
    }
    .request {
        font-size: 28px;
    }
    .upSkill {
        font-size: 12px;
    }
}
