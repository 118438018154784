.businessMain {
    margin: 72px auto 100px auto;
}

.businessHeading {
    display: flex;
    justify-content: center;
    color: #030945;
    font-weight: 700;
    font-size: 36px;
}

.businessInner {
    display: flex;
    flex-direction: row;
}

.digitalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    margin-top: 124px;
}

.digitalEcosystem {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
    width: 345px;
    padding: 0px 15px;
    border-radius: 24px;
    position: relative;
}

.digitalEcoHeading {
    padding-top: 68px;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    text-align: center;
    margin-bottom: 5px;
    color: #030945;
}

.digitalEcoDescription {
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.5;
    font-weight: 400;
    font-size: 18px;
}

.circleWhite {
    position: absolute;
    height: 86px;
    width: 86px;
    border-radius: 50%;
    background-color: #fff;
    top: -52px;
    right: 130px;
    box-shadow: 0px 5px 10px 0px #0000000d;
}

.businessRing {
    position: absolute;
    top: -62px;
    right: 120px;
}

.businessMain {
    margin: 72px auto 100px auto;
}

.flexIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@media only screen and (max-width: 1536px) {
    .businessHeading {
        font-size: 33px;
    }
    .digitalEcoHeading {
        font-size: 18px;
    }
    .digitalEcoDescription {
        font-size: 17px;
    }
}

@media only screen and (max-width: 1200px) {
    .businessHeading {
        font-size: 30px;
    }
    .digitalEcoHeading {
        font-size: 16px;
    }
    .digitalEcoDescription {
        font-size: 15px;
    }
    .digitalEcosystem {
        min-width: 250px;
        width: 250px;
        height: 370px;
    }
    .circleWhite {
        right: 35%;
    }
    .businessRing {
        right: 30%;
    }
    .circleWhite {
        height: 78px;
        width: 78px;
    }
}
@media only screen and (max-width: 900px) {
    .digitalEcosystem {
        min-width: 200px;
        width: 200px;
        height: 370px;
    }
    .circleWhite {
        height: 72px;
        width: 72px;
    }
    .businessRing {
        right: 28%;
        top: -18%;
    }
}

@media only screen and (max-width: 768px) {
    .digitalContainer {
        flex-direction: column;
        align-items: center;
        row-gap: 100px;
    }
    .businessHeading {
        text-align: center;
    }
    .digitalEcosystem {
        min-width: 300px;
        width: 300px;
        height: 320px;
        margin-left: 0px;
    }
    .circleWhite {
        right: 36%;
    }
    .businessRing {
        right: 31%;
        top: -21%;
    }
    .businessHeading {
        font-size: 24px;
    }
}

@media only screen and(max-width: 400px) {
    .digitalEcosystem {
        min-width: 200px;
        height: 250px;
        margin-left: 0px;
    }
    .businessHeading {
        font-size: 22px;
    }
}
