@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700&display=swap');
* {
    font-family: Poppins;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
}

.main-div {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;
}

/* -----------ANT FORM ITEM-------- */
.ant-form-item input,
.ant-form-item .ant-picker,
.ant-form-item .ant-select-selector {
    border-radius: 8px;
    padding: 15px;
    height: 56px !important;
    width: 100%;
}

.ant-form-item .ant-input-number {
    border-radius: 8px;
    height: 56px !important;
    width: 100%;
}

.ant-form-item .ant-select-single {
    height: auto;
}

.ant-form-item .ant-space-compact {
    width: 100%;
}

.ant-select .ant-select-arrow {
    font-size: 18px;
}

.loginSider {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* -----------ANT TABS---------- */
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 50px;
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: #8b8c9c;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #67daff;
}

/* -----------ANT RADIO------------ */
.ant-radio-wrapper .ant-radio-inner {
    width: 25px;
    height: 24px;
    border-width: 4px;
    border-color: #f5f5f5;
}

.ant-radio-wrapper .ant-radio-inner:hover {
    width: 25px;
    height: 24px;
    border-width: 4px;
    border-color: #67daff;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #67daff;
    background-color: #67daff;
    border-width: 4px;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
}

/* -----------ANT RADIO------------ */
.ant-menu-light .ant-menu-item {
    display: flex;
    align-items: center;
    column-gap: 18px;
    color: #677381;
    padding: 12px;
    margin-bottom: 10px;
    height: 48px;
    font-weight: 500;
}

.ant-menu-light .ant-menu-item-selected {
    color: #00b8d9;
    font-weight: 600;
    background-color: rgba(103, 218, 255, 0.08);
}

/* -----------ANT LIST------------ */
.ant-list-split .ant-list-item {
    border-block-end: transparent;
}

.ant-list .ant-list-item {
    padding: 0px;
    font-size: 18px;
}
