.serviceOuterContainer {
    background-color: #f6fbff;
    padding: 62px 100px;
}

.serviceTitle {
    margin: 0;
    margin-bottom: 32px;
    color: #030945;
    text-align: center;
    font-size: 44px;
}

.serviceTabContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 62px;
}

.serviceHeadingMain {
    font-size: 18px;
    margin: 0;
    color: #030945;
    font-weight: 700;
}

@media only screen and (max-width: 1200px) {
    .serviceOuterContainer {
        padding: 62px 70px;
    }
    .serviceHeadingMain {
        font-size: 16px;
    }
}
@media only screen and (max-width: 768px) {
    .serviceOuterContainer {
        padding: 40px;
    }
    .serviceTitle {
        font-size: 34px;
    }
}
@media only screen and (max-width: 400px) {
    .serviceOuterContainer {
        padding: 30px;
    }
}
