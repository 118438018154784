.contactMain {
    margin-top: 66px;
    background-image: url('../../../assets/images/contact.png');
    background-size: cover;
}

.contactInner {
    padding: 120px 200px 119px 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: #67daff;
    font-weight: 700;
    font-size: 44px;
}

.contactPara1 {
    margin-bottom: 10px;
    display: flex;
    color: #ffff;
    opacity: 85%;
    font-weight: 400;
    font-size: 20px;
    justify-content: center;
    text-align: center;
}

.contactPara2 {
    margin: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    opacity: 85%;
    font-weight: 400;
    font-size: 20px;
    color: #ffff;
    line-height: 40px;
}

.contactSource {
    display: flex;
    padding-top: 45px;
    justify-content: center;
    column-gap: 32px;
}

.contactWrapper {
    background-repeat: no-repeat;
    background-size: 35px;
    background-position-y: 4px;
    background-position-x: 4px;
    padding: 10px 60px 10px 60px;
    border: 1px;
    border-style: solid;
    border-radius: 110px;
    border-color: #ffffff80;
    color: #ffffff;
}

.contactNumber {
    background-image: url('../../../assets/icons/wp.png');
}

.contactEmail {
    background-image: url('../../../assets/icons/Email.png');
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .contactInner {
        padding: 120px 120px 119px 120px;
    }

    .contactHeader {
        font-size: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
    }
    .contactNumber,
    .contactEmail {
        display: flex;
        align-items: center;
        padding: 10px 50px 10px 50px;
        background-position-y: 4px;
    }

    .contactPara2 {
        line-height: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .contactInner {
        padding: 120px 50px 119px 50px;
    }
    .contactHeader {
        font-size: 30px;
    }
    .contactPara1 {
        font-size: 16px;
    }
    .contactPara2 {
        font-size: 16px;
    }
    .contactSource {
        display: flex;
        flex-direction: column;
    }
    .contactNumber,
    .contactEmail {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 60px 10px 60px;
        background-position-y: 4px;
    }
}
@media only screen and (max-width: 400px) {
    .contactHeader {
        font-size: 26px;
    }

    .contactNumber,
    .contactEmail {
        font-size: 14px !important;
        background-position-y: 2px;
    }
}
