.missionMain {
  margin: 70px 150px 120px 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 175px;
}
.missionOuterContainer {
  padding: 0px 150px;
}
.missionSvgInner {
  position: absolute;
  top: 100px;
  right: -60px;
  height: 441px;
  width: 303px;
}
.missionImg {
  height: 500px;
  width: 400px;
  z-index: 9999999999;
  position: relative;
}
.missionHeading {
  color: #030945;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 30px;
}
.missionList {
  font-weight: 400;
  color: #000000;
  font-size: 20px;
  line-height: 38px;
}

@media only screen and (max-width: 1536px) {
  .missionMain {
    margin: 100px 150px 120px 150px;
  }
  .missionImg {
    height: 450px;
    width: 350px;
  }
  .missionDesc {
    margin-right: auto;
  }
  .missionList {
    font-size: 16px;
  }
  .missionSvgInner {
    top: 60px;
    right: -50px;
  }
}

@media only screen and (max-width: 1200px) {
  .missionMain {
    margin: 70px 100px 120px 70px;
    column-gap: 100px;
  }
  .missionImg {
    height: 400px;
    width: 300px;
  }
  .missionHeading {
    font-size: 30px;
  }
  .missionSvgInner {
    top: 40px;
    right: -50px;
    height: 390px;
  }
}

@media only screen and (max-width: 820px) {
  .missionImg {
    height: 380px;
  }
  .missionOuterContainer {
    padding: 0px 100px;
  }
  .missionMain {
    display: flex;
    flex-direction: column;
  }
  .missionDesc {
    margin-left: auto;
    margin-top: 50px;
  }
  .missionSvgInner {
    top: 30px;
  }
  .missionHeading {
    font-size: 26px;
  }
}

@media only screen and (max-width: 450px) {
  .missionHeading {
    font-size: 28px;
  }
  .missionOuterContainer {
    padding: 0px 70px;
  }
  .missionList {
    font-size: 14px;
  }
}
