.infoOuter {
    margin: 111px 107px 100px 97px;
}

.infoOuterInner {
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.infoHeaderMain {
    color: #030945;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 20px;
}

.paragraph_1 {
    margin: 0px;
    color: #585858;
    font-size: 22px;
    font-weight: 400;
}

.infoImgSecondary {
    border-radius: 50px;
}

.infoOuterFirst {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1536px) {
    .infoOuter {
        margin: 120px 70px 120px 70px;
    }
    .infoOuterInner {
        column-gap: 80px;
        margin-right: 0px;
    }
    .infoHeaderMain {
        font-size: 34px;
    }
    .infoImgSecondary {
        width: 450px;
        height: 420px;
    }
    .paragraph_1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .infoHeaderMain {
        font-size: 28px;
    }
    .infoImgSecondary {
        width: 400px;
        height: 350px;
    }
    .infoOuter {
        margin: 60px 70px;
    }
    .infoOuterInner {
        column-gap: 40px;
    }
    .paragraph_1 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 850px) {
    .infoOuterInner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .infoHeaderMain {
        font-size: 26px;
    }
    .infoImgSecondary {
        width: 445px;
        height: 400px;
    }
    .infoImgOuter {
        margin-top: 40px;
    }
    .infoOuterFirst {
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {
    .infoHeaderMain {
        font-size: 28px;
    }
    .infoImgSecondary {
        width: 300px;
        height: 270px;
    }
    .paragraph_1 {
        font-size: 16px;
    }
}
