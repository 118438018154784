.scroll-to-top {
    position: fixed;
    bottom: 20px;
    /* bottom: 70px; */
    right: 20px;
    background-color: #67daff;
    color: white;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.scroll-to-top span {
    display: inline-block;
}

.scroll-to-top.active {
    display: block;
}
