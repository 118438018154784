.dark-logo-div img {
  width: 200px;
  margin-bottom: 1rem;
}

.dark-logo-div h2 {
  font-size: 18px;
  color: #979696;
}

.dark-logo-div .otp-button {
  margin-top: 1rem;
  width: 100%;
  color: #ffffff;
  background-color: #00152a;
  font-weight: 500;
  letter-spacing: 1px;
}

.dark-logo-div h2 {
  margin-bottom: 20px;
}

.dark-logo-div .otp-button:hover {
  color: #00152a;
  border: 1px solid #00152a;
  background: #ffffff;
}

.otp-style {
  justify-content: center;
  margin-top: 30px;
}

.input-otp-style {
  width: 54px !important;
  height: 54px !important;
  margin: 0 0.5rem;
  font-size: 1.3rem;
  font-weight: 700;
  background: "#eff0f4";
  border: 1px solid #919eab;
  color: #919eab;
  border-radius: 3px;
  padding: 0 !important;
}

.input-otp-style:first {
  margin-left: 0px !important;
}
