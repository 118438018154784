.visionDescription {
  font-size: 20px;
}
.visionContainer {
  flex-wrap: nowrap;
  column-gap: 82px;
  margin: 100px 0px;
}
.visionTextContainer {
  margin-right: 100px;
}

@media only screen and (max-width: 1200px) {
  .visionTextContainer {
    margin-right: 70px;
  }
  .visionDescription {
    font-size: 18px;
  }

  .visionImage {
    width: 500px;
  }
}

@media only screen and (max-width: 820px) {
  .visionDescription {
    font-size: 18px;
  }
  .visionContainer {
    flex-direction: column;
    align-items: start;
  }
  .visionImage {
    width: 520px;
  }
  .visionTextContainer {
    display: flex;
    justify-content: center;
    margin: 0px 30px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 540px) {
  .visionImage {
    width: 400px;
  }
}
