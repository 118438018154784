.blogOuterContainer {
    margin: 66px 150px 80px 150px;
}

.blogInnerDescription {
    font-size: 18px;
}
.blog_img {
    display: flex;
    justify-content: center;
}
.blog_img img {
    max-width: 100%;
}
.blog_detals p {
    font-size: 16px;
    margin: 10px 0;
}
.blog_detals h4 {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
}

.blogDetailOuter {
    padding: 48px 250px;
}

.outerBlogImageContainer {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: relative;
}

.outerBlogImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* or contain */
    border-radius: 20px;
}

.blogSectionDetail p,
.blogSectionDetail li,
.blogSectionDetail h4 {
    font-size: 18px;
}

.blogPageImg {
    border-radius: 13px;
    object-fit: cover;
}

@media only screen and (max-width: 1200px) {
    .blogDetailOuter {
        padding: 48px 130px;
    }
    .blogSectionDetail p,
    .blogSectionDetail li,
    .blogSectionDetail h4 {
        font-size: 17px;
    }
    .outerBlogImageContainer {
        height: 400px;
    }
    .blogPageImg {
        height: 300px;
    }
}

@media only screen and (max-width: 850px) {
    .blogDetailOuter {
        padding: 48px 80px;
    }
    .outerBlogImageContainer {
        height: 320px;
    }
    .blogSectionDetail p,
    .blogSectionDetail li,
    .blogSectionDetail h4 {
        font: 16px;
    }
    .blogOuterContainer {
        margin: 60px 50px;
    }
    .blogPageImg {
        height: 260px;
    }
}

@media only screen and (max-width: 600px) {
    .blogDetailOuter {
        padding: 48px 50px;
    }
    .outerBlogImageContainer {
        height: 250px;
    }

    .blogOuterContainer {
        margin: 60px 30px;
    }
}

@media only screen and (max-width: 450px) {
    .blogSectionDetail p,
    .blogSectionDetail li,
    .blogSectionDetail h4 {
        font-size: 15px;
    }
    .outerBlogImageContainer {
        height: 200px;
    }

    .blogOuterContainer {
        margin: 50px 20px;
    }
}
