.subscribe {
  background-color: #f9f9f9;
  border-radius: 20px;
  padding: 50px 62px;
  column-gap: 115px;
}

.container {
  margin: 0px 150px;
  justify-content: center;
}

.subscribeFlex {
  display: flex;
  align-items: center;
}

.mailBackgroundSvg {
  height: 152px;
  width: 283px;
}

.subscribe .image2 {
  position: absolute;
  top: 0;
  left: 84px;
  width: 117px;
  height: 113px;
}

.subscribe .input-container {
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
  font-size: 16px;
  padding: 16px 10px;
  border-radius: 100px;
  margin-right: 6px;
  width: 100%;
  /* width: 55%; */
  outline: none;
}

.flex {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .subscribe {
    padding: 50px;
    column-gap: 90px;
  }
  .titleSubscribe {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .subscribe {
    padding: 30px;
    column-gap: 40px;
  }
  .titleSubscribe {
    font-size: 16px !important;
  }
  .subscribe .col-right .input-container {
    /* width: 45%; */
    width: 100%;
  }
  .mailBackgroundSvg {
    width: 200px;
    min-width: 200px;
  }
  .subscribe .image2 {
    width: 70px;
    min-width: 70px;
    left: 66px;
  }
}

@media only screen and (max-width: 600px) {
  .subscribeFlex {
    flex-direction: column;
  }
}
