/* 1920, 1536, 1200, 768, 400 */
.chooseContainerMain {
    margin-right: 86px;
}

.chooseContainer {
    background-color: #eff6f9;
    height: 383px;
    width: 100%;
    padding: 67px 0px 0px 0px;
    border-radius: 0px 600px 600px 0px;
}

.chooseContainerInner {
    margin: 0px 0px 0px 150px;
}

.chooseContainerCard {
    padding: 16px 16px 0px 16px;
    border-radius: 14px;
    border: none;
    max-height: 120px;
    height: 120px;
    box-shadow: 0px 4px 8px 0px #00000008;
}

.chooseContainerText {
    font-size: 20px;
    font-weight: 700;
    margin: 67px 0px 35px 0px;
}

.chooseContainerRow {
    margin-top: 42px;
    row-gap: 13px;
}

.chooseCardIcon {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media only screen and (max-width: 1536px) {
    .chooseContainerText {
        font-size: 28px;
    }
    .chooseContainerCard {
        max-height: 130px;
        height: 130px;
    }
    .chooseContainerCard {
        padding: 10px 16px 0px 16px;
    }
}

@media only screen and (max-width: 1200px) {
    .chooseContainerText {
        font-size: 28px;
    }
    .chooseContainerInner {
        margin: 0px 0px 0px 80px;
    }
    .chooseContainer {
        height: 600px;
        padding: 40px 0px 0px 0px;
    }
    .chooseContainerCard {
        max-height: 155px;
        height: 155px;
        padding: 10px 16px 0px 16px;
    }
}
@media only screen and (max-width: 768px) {
    .chooseContainerInner {
        margin: 0px 0px 0px 40px;
    }
    .chooseContainerMainTitle {
        font-size: 30px !important;
    }
    .chooseContainerCard {
        max-height: 175px;
        height: 175px;
        padding: 10px 10px 0px 10px;
    }
}
@media only screen and (max-width: 620px) {
    .chooseContainerInner {
        margin: 0px 0px 0px 40px;
    }
    .chooseContainerMainTitle {
        font-size: 25px !important;
    }
    .chooseContainerCard {
        max-height: 165px;
        height: 165px;
        padding: 10px 10px 0px 10px;
    }
    .chooseCardDescription {
        font-size: 10px;
    }
}

@media only screen and (max-width: 380px) {
    .chooseContainerCard {
        max-height: 170px;
        height: 170px;
    }
    .chooseCardDescription {
        font-size: 9px;
    }
}
