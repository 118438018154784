.eventOuterContainer {
    padding: 0px 150px;
}

.eventTitle {
    margin: 0;
    margin-bottom: 32px;
    color: #030945;
    text-align: center;
}

.eventHeader {
    font-size: 18px;
    font-weight: 500;
}

.eventCard {
    height: 190px;
    min-height: 190px;
}

@media only screen and (max-width: 1536px) {
    .eventCard {
        height: 240px;
        min-height: 240px;
    }
    .eventOuterContainer {
        padding: 30px 100px;
    }
}
@media only screen and (max-width: 1200px) {
    .eventOuterContainer {
        padding: 40px;
    }
    .eventHeader {
        font-size: 16px;
        font-weight: 500;
    }
}
@media only screen and (max-width: 768px) {
    .eventOuterContainer {
        padding: 62px 70px;
    }
    .eventCard {
        height: auto;
        min-height: auto;
    }
}
@media only screen and (max-width: 500px) {
    .eventOuterContainer {
        padding: 62px 70px;
    }
    .flexColumnEvent {
        /* flex-direction: column; */
    }
    .eventHeader {
        font-size: 14px;
    }
}
