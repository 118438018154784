.ready {
    margin-bottom: 20px !important;
    font-weight: 500;
    font-size: 18px !important;
}
.joinToday {
    font-size: 26px !important;
}
.signUpNow {
    font-size: 18px !important;
    margin-top: 18px !important;
}
.button12 {
    display: flex;
    justify-self: center;
}
.joinfounderclinic2 {
    background-color: #cadbc4;
    width: 100%;
    padding: 30px 0;
}
.joinFounderInner2 {
    margin: 50px 128px 0px 128px;
}
.clinicMain2 {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 1536px) {
    .ready {
        font-size: 16px;
    }
    .joinToday {
        font-size: 24px;
    }
    .signUpNow {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1200px) {
    .joinImg2 {
        max-width: 350px;
    }
    .ready {
        font-size: 14px;
    }
    .joinToday {
        font-size: 22px !important;
    }
    .signUpNow {
        font-size: 16px !important;
    }
    .joinFounderInner2 {
        margin: 50px 50px 0px 50px;
    }
}

@media only screen and (max-width: 768px) {
    .clinicMain2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }
    .joinfounderclinic2 {
        background-color: #cadbc4;
        min-width: 60%;
       
    }
    .joinImg2 {
        min-width: 800px;
    }
    .joinFounderInner2 {
        margin: 50px 50px 0px 50px;
    }
}

@media only screen and (max-width: 600px) {
    .joinFounderInner2 {
        margin: 30px 30px 0px 30px;
    }
    .clinicMain2 {
        margin-top: 50px;
    }
}
