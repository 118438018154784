.button_main {
  padding: 15px 26px 15px 26px;
  border-radius: 200px;
  border: none;
  color: rgba(1, 1, 1, 1);
  font-weight: 500;
  height: auto;
  font-size: 14px;
  background: rgba(103, 218, 255, 1);
}
@media only screen and (max-width: 820px) {
  .button_main {
    padding: 13px 21px 13px 21px;

    font-size: 13px;
  }
}

.button_main:hover {
  color: #fff !important;
  background-color: rgba(103, 218, 255, 1) !important;
}

.button_dashboard {
  border-radius: 8px;
  padding: 12px 22px;
  color: white;
}

.deletePopUp .transParentButton.button_main:hover {
  background-color: transparent !important;
}

.deletePopUp .button_main:hover {
  background-color: #ff0000 !important;
}
