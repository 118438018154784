.circleContainer {
    margin: 50px 70px 0px 70px;
    background-color: rgba(103, 218, 255, 0.15);
    padding: 63px 60px;
    border-radius: 45px;
}

.circleContainerHeading {
    font-weight: 700;
    font-size: 36px;
    color: #030945;
    margin: 0;
    text-align: center;
}

.circleArrowStyle {
    position: absolute;
    right: -60px;
    bottom: -58px;
    height: 78px;
    width: 78px;
}

.circleContainerList {
    margin-top: 45px;
    border-block-end: transparent;
}

@media only screen and (max-width: 1200px) {
    .circleContainerHeading {
        font-size: 30px;
    }
    .circleContainer {
        padding: 50px 34px;
    }
}
@media only screen and (max-width: 768px) {
    .circleContainer {
        margin: 40px 40px 0px 40px;
        padding: 30px;
    }
    .circleContainerHeading {
        font-size: 20px;
    }
    .circleContainerList.ant-list .ant-list-item {
        font-size: 16px;
    }
    .circleArrowStyle {
        position: absolute;
        right: -60px;
        bottom: -66px;
    }
}

@media only screen and (max-width: 520px) {
    .circleContainer {
        margin: 30px 25px 0px 25px;
        padding: 20px;
    }

    .circleArrowStyle {
        height: 50px;
        width: 50px;
        bottom: -40px;
        right: -40px;
    }

    .circleContainerHeading {
        font-size: 16px;
    }
    .circleContainerList.ant-list .ant-list-item {
        font-size: 14px;
    }
    .circleContainerList {
        margin-top: 30px;
    }
}
@media only screen and (max-width: 400px) {
    .circleContainerHeading {
        font-size: 14px;
    }
    .circleContainerList.ant-list .ant-list-item {
        font-size: 12px;
    }
}
