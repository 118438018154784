.ecosystemInfo {
    background-color: #9cc6bf;
    border-radius: 0px 20px 20px 0px;
    position: relative;
    max-height: 460px;
    height: 460px;
}
.ecoSystemPlay {
    background-color: #fff;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    position: absolute;
    left: -5%;
    top: 50%;
}
.ecoImage {
    height: 460px !important;
    max-height: 460px !important;
}

.para1EcoSystem {
    font-weight: 500;
    font-size: 20px;
    color: #030945;
}
.para2EcoSystem {
    font-weight: 400;
    font-size: 20px;
    color: #030945;
}

.textEcoContainer {
    padding: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
@media only screen and (max-width: 1200px) {
    .para1EcoSystem {
        font-size: 18px;
    }
    .para2EcoSystem {
        font-size: 17px;
    }
    .ecoImage {
        height: 360px !important;
        max-height: 360px !important;
    }
    .ecosystemInfo {
        height: 360px;
        max-height: 360px;
    }
    .textEcoContainer {
        row-gap: 10px;
        padding: 30px;
    }
    .ecoSystemPlay {
        left: -6%;
    }
}
@media only screen and (max-width: 768px) {
    .para1EcoSystem {
        font-size: 15px;
    }
    .para2EcoSystem {
        font-size: 14px;
    }
    .ecoSystemPlay {
        left: -8%;
    }
    .ecoSystemPlay {
        height: 45px;
        width: 45px;
    }
    .heading1 {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 500px) {
    .heading1 {
        font-size: 16px !important;
    }
    .ecoSystemPlay {
        left: -11%;
    }
    .para1EcoSystem {
        font-size: 12px;
    }
    .para2EcoSystem {
        font-size: 12px;
    }
    .textEcoContainer {
        padding: 20px;
    }
}
