.membershipPlanContainer {
    padding: 30px 70px 70px 70px;
}

.membershipPlanRow {
    margin-top: 62px;
    flex-direction: column;
    justify-content: center;
}

.membershipPlanText {
    color: #0c93bf;
    font-size: 20px;
    font-weight: 500;
    margin: 0px 0px 10px 0px;
    text-align: center;
}

.planTitle {
    border-radius: 30px 30px 0px 0px;
    height: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.planCard {
    max-width: 487px;
    border: none;
    height: auto;
    /* height: 870px; */
}

.planCard .ant-card-body {
    height: auto;
    /* height: 862px; */
    border-radius: 30px;
    padding: 0;
    box-shadow: 0px 12px 20px 0px #0000000a;
}
.planDescriptionContainer {
    row-gap: 32px;
    display: flex;
    flex-direction: column;
    margin: 24px 20px;
}
.planDescriptionRow {
    column-gap: 16px;
    flex-wrap: nowrap;
}
.planDescriptionIcon {
    width: 17px;
    min-width: 17px;
}
.planDescriptionText {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
}

@media only screen and (max-width: 1200px) {
    .planDescriptionText {
        font-size: 16px;
    }
    .planCard {
        width: 550px;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
    .planDescriptionContainer {
        row-gap: 20px;
    }
    .membershipPlanTitle {
        font-size: 35px !important;
    }
}
@media only screen and (max-width: 768px) {
    .planCard {
        width: 450px;
        max-width: 450px;
    }
    .membershipPlanContainer {
        padding: 0px 30px 60px 30px;
    }

    .membershipPlanTitle {
        font-size: 30px !important;
    }
    .membershipPlanText {
        font-size: 20px;
    }
    .membershipPlanColumn {
        display: flex;
        justify-content: center;
        margin-left: 0px;
        margin-right: 0px;
    }
    .planCard {
        height: auto;
    }

    .planCard .ant-card-body {
        height: auto;
    }
}
@media only screen and (max-width: 500px) {
    .planCard {
        width: 350px;
        max-width: 350px;
    }
}
@media only screen and (max-width: 400px) {
    .planCard {
        width: 270px;
        max-width: 270px;
    }
    .membershipPlanTitle {
        font-size: 22px !important;
    }
    .membershipPlanText {
        font-size: 14px;
    }
}
