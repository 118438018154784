.clinicMain {
    margin-top: 150px;
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    background-color: #c1c4ed;
    position: relative;
}
.joinfounderclinic {
    width: 90%;
    padding: 30px 0;
}

.joinFounderInner {
    margin: 44px 115px 0px 115px;
    row-gap: 0px;
    width: 45%;
}
.ready {
    margin-bottom: 20px !important;
    font-weight: 500;
    font-size: 18px !important;
}
.joinToday {
    font-size: 26px !important;
}
.signUpNow {
    font-size: 18px !important;
    margin-top: 18px !important;
}
.joinImg1 {
    position: absolute;
    width: 590px;
    height: 390px;
    border-radius: 50px;
    right: 150px;
    bottom: -22px;
}

@media only screen and (max-width: 1536px) {
    .clinicMain {
        margin-top: 150px;
    }
    .ready {
        font-size: 16px;
    }
    .joinToday {
        font-size: 24px;
    }
    .signUpNow {
        font-size: 16px;
    }
    .joinFounderInner {
        width: 40%;
    }
    .joinImg1 {
        max-width: 530px;
        right: 50px;
        bottom: -20px;
    }
    .joinFounderInner {
        margin: 44px 90px 0px 90px;
    }
}

@media only screen and (max-width: 1200px) {
    .clinicMain {
        margin-top: 100px;
    }
    .joinImg1 {
        max-width: 400px;
        right: 50px;
        height: 300px;
        bottom: -10px;
    }
    .joinFounderInner {
        width: 35%;
    }
    .ready {
        font-size: 14px;
    }
    .joinToday {
        font-size: 22px !important;
    }
    .signUpNow {
        font-size: 16px !important;
    }
    .joinFounderInner {
        margin: 20px 40px 0px 40px;
    }
   
}

@media only screen and (max-width: 768px) {
    .clinicMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        margin-top: 130px;
    }
    .joinfounderclinic {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        background-color: #c1c4ed;
    }
    .joinImg1 {
        /* min-width: 770px; */
        position: relative;
        border-radius: 0px;
        bottom: 0;
        width: 100%;
        right: 0;
        min-width: 100%;
    }
    .joinFounderInner {
        margin: 0px;
        row-gap: 0px;
    }
    .joinFounderInner {
        width: 60%;
    }
}

@media only screen and (max-width: 620px) {
    .clinicMain {
        margin-top: 280px;
    }
}

@media only screen and (max-width: 400px) {
    .joinFounderInner {
        margin: 30px 30px 0px 30px;
        row-gap: 0px;
    }
}
